<template>
  <div>
    <save
      ref="saveForm"
      @onSuccess="getItems"
    />
    <b-tabs>
      <b-tab
        v-for="tab in settingTabs"
        :key="tab.id"
        :title="tab.name"
        @click="onChange(tab.id)"
      >
        <b-overlay :show="loading">
          <good-table-column-search
            v-if="items"
            :items="items.data"
            :columns="columns"
            :total="items.total"
            :page="page"
            :filter="filterModel"
            @getItems="getItems"
            @add="$refs.saveForm.visible = true"
            @onPageChange="(p) => page = p"
          />
        </b-overlay>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch, Save,
  },
  data() {
    return {
      page: 1,
      loading: false,
      items: [],
      type: 0,
      filterModel: {
        per_page: 50,
        number: null,
        address: null,
        region_id: null,
        city_id: null,
        calculating_type_id: null,
      },
      columns: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: 'Значение',
          field: 'amount',
          isAmount: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Дата создания',
          field: 'start_date',
          isDate: true,
        },
        {
          label: 'Дата окончания',
          field: 'end_date',
          isDate: true,
        },

      ],
      settingTabs: [
        {
          id: 0,
          name: 'Tizimda valyuta kursi',
        },
        {
          id: 1,
          name: 'Real valyuta kursi',
        },
      ],
    }
  },
  computed: {
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'resource/getDollarRates',
      getRealItemsAction: 'resource/getRealDollarRates',
      // destroyAction: 'resource/destroyUnitOfMeasure',
    }),
    async getItems() {
      this.loading = true
      await this.actionMethod({ ...this.filterModel, page: this.page })
        .then(res => {
          this.items = res.data
        })
      this.loading = false
    },
    actionMethod(data) {
      if (this.type == 1) {
        return this.getRealItemsAction(data)
      }
      return this.getItemsAction(data)
    },
    onChange(id) {
      this.type = id
      this.changeQuery('type', id)
      this.getItems()
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') { return nameuz }
      return nameru
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getItems()
            }).catch(() => {
              showToast('success', this.$t('Успешно удалено'))
            })
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
