<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2 row"
      >
        <b-form-group
          :label="$t('Значение')"
          label-for="amount"
          class="col-12"
        >
          <validation-provider
            #default="{ errors }"
            name="amount"
            rules="required"
          >
            <cleave
              id="amount"
              v-model="form.amount"
              class="form-control"
              :state="errors.length > 0 ? false:null"
              :options="cleaveOption"
              placeholder="0"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver, Cleave,
  },
  props: {
  },
  data() {
    return {
      form: {
        id: null,
        amount: '',
        name_ru: '',
      },
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    itemId() {
      return this.$route.query.id
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {
    this.title = this.$t('Добавить значение')

    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    getCities(cityId) {
      this.fetchCities({ region_id: cityId })
    },
    edit(item) {
      this.form.id = item.id
      this.form.name_uz = item.name_uz
      this.form.name_ru = item.name_ru
      this.visible = true
    },
    method(data) {
      if (this.$route.query.type) return this.storeRealItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      storeItem: 'resource/storeDollarRates', storeRealItem: 'resource/storeRealDollarRates',
    }),
  },
}
</script>

<style scoped>

</style>
